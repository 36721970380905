<template>
    <div class="content">
        <div class="left">
            <div class="add" @click="handleAddProject">+新增项目</div>
            <div class="project-one" v-for="item in projectList" @click="handleOneProject(item)">{{item.name}}</div>
        </div>
        <div class="right">
            <div>
                <span class="label">项目名称</span>
                <input type="text" v-model="projectName">
            </div>
            <div class="building-list">
                <span class="label">楼栋</span>
                <input type="text" placeholder="楼栋层数(32)" disabled>
                <input type="text" placeholder="楼栋户数(4)" disabled>
                <input type="text" placeholder="楼栋厚度(1)" disabled>
                <input type="text" placeholder="楼栋位置（xy）(0,0)" disabled>
                <span @click="handleAddBuild">+增加楼栋</span>
            </div>
            <div class="building-list" v-for="item in buildingList">
                <span class="label">楼栋</span>
                <input type="text" placeholder="楼栋层数" v-model="item.h">
                <input type="text" placeholder="楼栋户数" v-model="item.w">
                <input type="text" placeholder="楼栋厚度" v-model="item.z" disabled>
                <input type="text" placeholder="楼栋位置（xy）" v-model="item.xyz">
            </div>

            <div>
                <button @click="handleSave">保存</button>
                <button @click="handleView">预览</button>

            </div>

        </div>
    </div>
</template>
<script>
export default {
    name: "threeManage",
    data() {
        return {
            id: null,
            projectName: '测试项目',
            buildingList: [
                {
                    h: 32,
                    w: 4,
                    z: 1,
                    xyz: '0,0'
                },
                {
                    h: 32,
                    w: 4,
                    z: 1,
                    xyz: '10,10'
                }
            ],
            projectList: [],
        }
    },
    components: {

    },
    created() {
        this.getList()
        // if (!!localStorage.user && !!localStorage.password){
        //     this.params.user = localStorage.user
        //     this.params.password = localStorage.password
        // }
        document.title = '日照管理'
    },
    mounted() {

    },
    methods: {
        handleAddBuild() {
            this.buildingList.push({
                h: 32,
                w: 4,
                z: 1,
                xyz: this.buildingList.length * 10+','+this.buildingList.length * 10
            })
        },
        handleAddProject() {
            // this.projectList.push({
            //     name: '项目'
            // })
            this.id = null
            this.projectName = '测试项目' + this.projectList.length
            this.buildingList = [
                {
                    h: 32,
                    w: 4,
                    z: 1,
                    xyz: '0,0'
                },
                {
                    h: 32,
                    w: 4,
                    z: 1,
                    xyz: '10,10'
                }
            ]
        },
        handleOneProject(data) {
            this.id = data.id
            this.projectName = data.name
            this.buildingList = JSON.parse(data.building_list)
            // this.$router.push(`/three?id=${data.id}`)

        },
        handleView() {
            this.save('go')
        },
        async getList(type) {
            // let params = {
            //     // code: 2
            //     name: this.projectName,
            //     list: JSON.stringify(this.buildingList)
            // }
            // https://muyu.spjgzs.com/api/jh/muyu/record
            this.projectList = []
            let res = await this.$util.ajax('/jh/muyu/searchThree', 'post' ,{})
            console.log(res)
            if (!!res && !!res.data) {
                res.data.forEach(item=>{
                    item.name = item.name || '无项目名称'
                    item.buildingList = !!item.building_list?JSON.parse(item.building_list):[]
                    this.projectList.push(item)
                })
                this.projectName = '测试项目' + this.projectList.length
            }
        },
        async handleSave() {
            this.save()
        },
        async save(type) {
            let params = {
                id: this.id,
                name: this.projectName,
                building_list: JSON.stringify(this.buildingList)
            }
            // https://muyu.spjgzs.com/api/jh/muyu/record
            let res = await this.$util.ajax('/jh/muyu/createThree', 'post' ,params)
            console.log(res)
            this.getList(type)
            if (type === 'go') {
                // this.$router.push(`/three?id=${res.data.id}`)
                window.open(`${window.location.origin}/three?id=${res.data.id}`)
            }
        },
        submit() {
            let params = this.params
            console.log(params)
            this.$util.ajax('/jh/user/login', 'POST', params).then(res => {
                if (res.code === 200) {
                    for (let _key in res.data) {
                        localStorage.setItem(_key,res.data[_key])
                    }
                    this.$router.push("/dashboard")
                }
                console.log(res)
                // this.trade_no = res.data.trade_no
                // this.alipay()
                // console.log(res)
                // if (!!res.openid) {
                //     localStorage.user_id = res.user_id
                //     this.user_id = res.user_id
                // }
            })
        }
    }
}
</script>

<style scoped lang="less">
.content {

        width: 960px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        /*align-items: center;*/

    .left {
        width: 160px;
    }
    .project-one {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
    }
    .add {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
    }
    .right {

    }

}
</style>
